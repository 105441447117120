<template>
  <v-card flat class="mt-2 mb-4">
    <v-card-text>
      <p class='mb-0 text--primary'>月額会費以外のお支払いがある場合は、こちらで管理してください。</p>
      <v-row>
        <v-spacer></v-spacer>
        <v-btn class="ml-auto ma-2" outlined color="grey darken-2" @click="reload">
          <v-icon left>mdi-reload</v-icon>更新
        </v-btn>
        <v-btn class="ml-auto ma-2" outlined color="grey darken-2" @click="addDialog">
          <v-icon left>mdi-plus</v-icon>項目を追加
        </v-btn>
      </v-row>
      <v-data-table
        :headers='headers'
        :items='list'
        :items-per-page='15'
        hide-default-footer
        item-key="index"
        :loading='loading'
        loading-text='読み込み中です'
        no-data-text='対象のデータがありません'
      >
        <template v-slot:item.price='{ item }'>
          {{ formatPrice(item.price) }}
        </template>
        <template v-slot:item.createdAt='{ item }'>
          {{ formatTime(item.createdAt) }}
        </template>
        <template v-slot:item.updatedAt='{ item }'>
          {{ formatTime(item.updatedAt) }}
        </template>
        <template v-slot:item.updatedBy='{ item }'>
          {{ item.updatedBy.name }}
          <p
            class='mb-0 text--secondary v-messages'
            style='line-height: 1.5;'
            v-if='item.updatedBy.nameKana'
          >
          ({{ item.updatedBy.nameKana }})
          </p>
        </template>
        <template v-slot:item.feePaid='{ item }'>
          {{ formatStatus(item.feePaid) }}
        </template>
        <template v-slot:item.edit="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                v-on="on"
                @click="editDialog(item)"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span>詳細/編集</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card-text>
    <fee-pay-status-dialog v-model="dialog" :uid="user.uid" :feePayStatus="editItem" @reload="reload()"></fee-pay-status-dialog>
  </v-card>
</template>

<script>
import moment from 'moment';
import FeePayStatusDialog from './FeePayStatusDialog';
import { FeePayStatusUseCase } from '../useCase/feePayStatusUseCase';

export default {
  components: {
    FeePayStatusDialog,
  },
  name: 'fee-pay-status',
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  async mounted() {
    await this.reload();
  },
  computed: {
    userInfo() {
      return this.$store.getters.user;
    },
  },
  watch: {
    async user() {
      if (this.user.uid) {
        await this.reload();
      }
    },
  },
  data() {
    return {
      headers: [
        { text: '項目名', sortable: false, value: 'name', width: '15%' },
        { text: '金額', sortable: false, value: 'price', width: '5%' },
        { text: 'メモ', sortable: false, value: 'memo', width: '20%' },
        { text: '作成日時', sortable: false, value: 'createdAt', width: '10%' },
        { text: '更新日時', sortable: false, value: 'updatedAt', width: '10%' },
        { text: '更新者', sortable: false, value: 'updatedBy', width: '10%' },
        { text: 'ステータス', sortable: false, value: 'feePaid', width: '10%' },
        { text: '詳細/編集', sortable: false, value: 'edit', width: '10%' },
      ],
      list: [],
      editItem: {},
      loading: false,
      dialog: false,
    };
  },
  methods: {
    async reload() {
      this.loading = true;
      this.list = await FeePayStatusUseCase.getByUserId(this.user.uid);
      this.loading = false;
    },
    addDialog() {
      this.editItem = {};
      this.dialog = true;
    },
    editDialog(item) {
      this.editItem = item;
      this.dialog = true;
    },
    formatTime(value) {
      return moment(new Date(value)).format('YYYY-MM-DD HH:mm');
    },
    formatStatus(value) {
      return value ? '受領' : '未納';
    },
    formatPrice(value) {
      const price = value.toLocaleString();
      return `¥${price}`;
    },
  },
};
</script>
