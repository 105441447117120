import { FeePayStatus } from '../model/feePayStatus';
import { FeePayStatusCloudFunction } from '../../infrastructure/firebase/cloudFunction/feePayStatusCloudFunction';

export class FeePayStatusRepository {
  static create(uid, param) {
    const feePayStatus = new FeePayStatus(param).toObject();
    return FeePayStatusCloudFunction.create(uid, feePayStatus);
  }

  static get(uid) {
    return FeePayStatusCloudFunction.get(uid);
  }

  static delete(param) {
    const feePayStatus = new FeePayStatus(param);
    return FeePayStatusCloudFunction.create(feePayStatus);
  }

  static update(uid, param) {
    const feePayStatus = new FeePayStatus(param).toObject();
    return FeePayStatusCloudFunction.update(uid, feePayStatus);
  }
}
