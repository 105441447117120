export default {
  data: () => ({
    SHIFT_TYPE: {
      lesson: {
        text: 'レッスン',
        value: 'lesson',
        color: 'green lighten-3',
      },
      free: {
        text: 'フリーレンジ',
        value: 'free',
        color: 'blue lighten-3',
      },
      trial: {
        text: '体験',
        value: 'trial',
        color: 'orange lighten-3',
      },
      close: {
        text: '休館',
        value: 'close',
        color: 'pink lighten-3',
      },
      maintenance: {
        text: 'メンテナンス',
        value: 'maintenance',
        color: 'grey lighten-1',
      },
      empty: {
        text: '空き',
        value: 'empty',
        color: 'pink lighten-3',
      },
      lefty: {
        text: 'レフティ',
        value: 'lefty',
        color: 'grey lighten-3',
      },
    },
    ROLL_TEXT: {
      admin: '管理者',
      staff: 'スタッフ',
      instructor: 'インストラクター',
      member: '会員',
    },
    ROLL_VALUE: {
      ADMIN: 'admin',
      STAFF: 'staff',
      INSTRUCTOR: 'instructor',
      MEMBER: 'member',
    },
  }),
};
