<template>
  <v-card flat class="mt-2 mb-4">
    <v-card-actions v-if='!Object.keys(karte).length && !loading'>
      <v-row>
        <v-col cols='12' class='d-flex justify-center py-0'>
          <v-card flat class="mt-2 mb-4">
            <p class='mb-0 text--primary'>
              レッスン履歴が登録されていません。
            </p>
          </v-card>
        </v-col>
        <v-col cols='12' class='d-flex justify-center py-0'>
          <v-card flat class="mx-5">
            <v-btn v-if="!isEdit" outlined color="grey darken-2" @click="createKarte(true)">
              <v-icon left>mdi-note</v-icon>レッスン履歴を登録する
            </v-btn>
          </v-card>
        </v-col>
      </v-row>
    </v-card-actions>
    <v-card-text v-else>
      <v-row>
        <v-btn class="ml-auto ma-2" outlined color="grey darken-2" @click="addKarteHistory">
          <v-icon left>mdi-plus</v-icon>履歴を追加
        </v-btn>
      </v-row>
      <v-data-table
        :headers='headers'
        :items='historyItems'
        :items-per-page='200'
        hide-default-footer
        item-key="index"
        :loading='loading'
        loading-text='読み込み中です'
        no-data-text='対象のデータがありません'
      >
        <template v-slot:item.index="{ item }">
          <td>{{ item.index }}回目</td>
        </template>
        <template v-slot:item.instructor='{ item }'>
          {{ item.instructor.name }}
          <p
            class='mb-0 text--secondary v-messages'
            style="line-height: 1.5;"
            v-if='item.instructor.nameKana'
          >
          ({{ item.instructor.nameKana }})
          </p>
        </template>`
        <template v-slot:item.edit="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                v-on="on"
                @click="openDialog(item.index)"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span>詳細/編集</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card-text>
    <karte-history-dialog v-if="karte" v-model="editDialog" :uid="user.uid" :karte="karte" :editItem="editItem" :karteId="karteId" @reload="reload(1)"></karte-history-dialog>
  </v-card>
</template>

<script>
import lodash from 'lodash';
import KarteHistoryDialog from './KarteHistoryDialog';
import util from '../util';

export default {
  components: {
    KarteHistoryDialog,
  },
  name: 'karte-history',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    user: {
      type: Object,
      required: true,
    },
  },
  async mounted() {
    await this.reload(1);
  },
  computed: {
    userInfo() {
      return this.$store.getters.user;
    },
  },
  watch: {
    async user() {
      if (this.user.uid) {
        await this.reload(1);
      }
    },
  },
  data() {
    return {
      headers: [
        { text: 'レッスン回数', align: 'start', sortable: false, value: 'index', width: '120px' },
        { text: '日付', sortable: false, value: 'date', width: '120px' },
        { text: '担当コーチ', sortable: false, value: 'instructor', width: '150px' },
        { text: '取り組んだこと', sortable: false, value: 'lesson', width: '120px' },
        { text: 'メモ', sortable: false, value: 'memo' },
        { text: '詳細/編集', sortable: false, value: 'edit', width: '100px' },
      ],
      karteId: '',
      karte: {},
      historyItems: [],
      isEdit: false,
      settings: [],
      clubs: ['P', '7', 'UT', 'FW', 'D', 'Pt'],
      loading: false,
      editDialog: false,
      editItem: {},
      expanded: [],
    };
  },
  methods: {
    async reload(index) {
      this.loading = true;
      this.karte = await util.callCloudFunction('getKarteHistory', { uid: this.user.uid, index });
      if (Object.keys(this.karte).length) {
        this.karteId = this.karte.id;
        this.historyItems = this.karte.doc.items;
        this.historyItems = lodash.orderBy(this.historyItems, 'index', 'desc');
      } else {
        this.karteId = '';
        this.historyItems = [];
      }
      this.loading = false;
    },
    async createKarte(forBeginner) {
      this.loading = true;
      await util.callCloudFunction('createKarte', { uid: this.user.uid, forBeginner });
      await this.reload(1);
      this.loading = false;
    },
    async addKarteHistory() {
      this.loading = true;
      await util.callCloudFunction('addKarteHistory', { uid: this.user.uid, karteId: this.karteId, doc: this.karte.doc });
      await this.reload(1);
      this.loading = false;
    },
    openDialog(index) {
      this.editItem = this.karte.doc.items.find((v) => v.index === index);
      this.editDialog = true;
    },
  },
};
</script>
<style>
.v-data-table tr:hover td {
    background-color: white;
}
</style>
