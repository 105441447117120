<template>
  <v-dialog persistent scrollable v-model="dialog" max-width="1280">
    <v-card>
      <v-card-title>
        <span class='text-h5'>基本情報の編集</span>
      </v-card-title>
      <v-card-text class='pb-0'>
        <v-container>
          <v-form v-model="valid" ref="form" lazy-validation :disabled='loading'>
            <v-card-text>
              <v-row>
                <v-col cols='12' class='d-flex justify-space-between py-0'>
                  <v-text-field
                    v-model='karteInfo.golfHistory'
                    outlined
                    dense
                    validate-on-blur
                    prefix='ゴルフ歴：'
                    placeholder='1年'
                  ></v-text-field>
                  <p class='mx-2'></p>
                  <v-text-field
                    v-model='karteInfo.averageScore'
                    outlined
                    dense
                    validate-on-blur
                    prefix='平均スコア：'
                    placeholder='100'
                  ></v-text-field>
                </v-col>
                <v-col cols='12' class='py-0'>
                  <p class='mb-0 text--primary'>
                  入会目的
                  </p>
                  <v-textarea
                    v-model='karteInfo.purpose'
                    outlined
                    dense
                    validate-on-blur
                    placeholder='スコアアップ'
                    rows=2
                  ></v-textarea>
                </v-col>
                <v-col cols='12' class='py-0'>
                  <p class='mb-0 text--primary'>
                  伝達事項
                  </p>
                  <v-textarea
                    v-model='karteInfo.message'
                    outlined
                    dense
                    validate-on-blur
                    placeholder='次回○○予定など'
                    rows=2
                  ></v-textarea>
                </v-col>
                <v-col cols='12' class='py-0'>
                  <p class='mb-0 text--primary'>
                  病気・ケガ
                  </p>
                  <v-textarea
                    v-model='karteInfo.noi'
                    outlined
                    dense
                    validate-on-blur
                    placeholder='腰痛あり'
                    rows=2
                  ></v-textarea>
                </v-col>
                <v-col cols='12' class='py-0'>
                  <p class='mb-0 text--primary'>
                  短期目標
                  </p>
                  <v-textarea
                    v-model='karteInfo.stg'
                    outlined
                    dense
                    validate-on-blur
                    placeholder='定期的にレッスンを受けてゴルフを習慣にする。など'
                    rows=2
                  ></v-textarea>
                </v-col>
                <v-col cols='12' class='py-0'>
                  <p class='mb-0 text--primary'>
                  長期目標
                  </p>
                  <v-textarea
                    v-model='karteInfo.ltg'
                    outlined
                    dense
                    validate-on-blur
                    placeholder='平均スコア 90 以下でプレーできるレベルを目指す！など'
                    rows=2
                  ></v-textarea>
                </v-col>
                <v-col cols='12' class='py-0'>
                  <p class='mb-0 text--primary'>
                  スクール案内について
                  </p>
                  <p
                    class='mb-0 text--secondary v-messages'
                    style='line-height: 1.5;'
                  >
                    案内済みの内容にチェックを付けてください。
                  </p>
                  <v-divider></v-divider>
                  <v-checkbox v-model="karteInfo.guidance[0]" label="施設のマナーやルール"/>
                  <v-divider></v-divider>
                  <v-checkbox v-model="karteInfo.guidance[1]" label="シュミレーターの見方や使い方"/>
                  <v-divider></v-divider>
                  <v-checkbox v-model="karteInfo.guidance[2]" label="ラウンドレッスンや家庭教師の案内"/>
                </v-col>
              </v-row>
            </v-card-text>
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color='grey darken-1' dark outlined @click="dialog = false" :disabled="loading">
          <v-icon class='pr-1'>mdi-close</v-icon>
          閉じる
        </v-btn>
        <v-btn color='blue darken-2' dark outlined @click="save" :loading="loading">
          <v-icon class='pr-1'>mdi-content-save-outline</v-icon>
          保存
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import util from '../util';

export default {
  name: 'karte-info-dialog',
  props: {
    karteInfo: {
      type: Object,
      required: true,
    },
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialog: false,
      loading: false,
      valid: true,
    };
  },
  watch: {
    dialog() {
      this.$emit('input', this.dialog);
    },
    value() {
      this.dialog = this.value;
    },
  },
  computed: {
    userInfo() {
      return this.$store.getters.user;
    },
  },
  methods: {
    async save() {
      try {
        this.loading = true;
        const param = {
          uid: this.karteInfo.uid,
          karteInfo: this.karteInfo,
        };
        await util.callCloudFunction('editKarteInfo', param);
        this.$store.commit('snackbar/open', { text: '基本情報を編集しました。', color: 'success' });
        this.loading = false;
        this.$emit('reload');
        this.dialog = false;
      } catch (error) {
        this.$store.commit('snackbar/open', { text: '基本情報の更新に失敗しました。', color: 'error' });
        this.loading = false;
      }
    },
  },
};
</script>
