<template>
  <v-dialog persistent scrollable v-model="dialog" max-width="640">
    <v-card>
      <v-card-title>
        <span class='text-h5'>お支払い項目の{{ action }}</span>
      </v-card-title>
      <v-card-text class='pb-0'>
        <v-container>
          <v-form ref="form" lazy-validation @submit.prevent>
            <v-card-text>
              <v-row>
                <v-col v-if='isEdit' cols='12' class='py-0'>
                  <v-checkbox
                    v-model='sendParam.feePaid'
                    label='受領済みの場合はチェックしてください。'
                    class='text--secondary  v-messages'
                  ></v-checkbox>
                </v-col>
                <v-col cols='12' class='py-0'>
                  <p class='mb-0 text--primary'>
                    項目名
                    <span class='required-label'>
                      必須
                    </span>
                  </p>
                  <v-text-field
                    v-model='sendParam.name'
                    outlined
                    dense
                    validate-on-blur
                    placeholder='〇〇の代金など'
                    :rules='[formRules.required]'
                  ></v-text-field>
                </v-col><v-col cols='12' class='py-0'>
                  <p class='mb-0 text--primary'>
                    金額
                    <span class='required-label'>
                      必須
                    </span>
                  </p>
                  <v-text-field
                    v-model.number='sendParam.price'
                    outlined
                    dense
                    validate-on-blur
                    suffix='円'
                    :rules='[formRules.required, formRules.price]'
                  ></v-text-field>
                </v-col>
                <v-col cols='12' class='py-0'>
                  <p class='mb-0 text--primary'>
                  メモ
                  </p>
                  <v-textarea
                    v-model='sendParam.memo'
                    outlined
                    dense
                    validate-on-blur
                    placeholder='次回○○予定など'
                    rows=2
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-card-text>
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color='grey darken-1' dark outlined :disabled="loading" @click="close">
          <v-icon class='pr-1'>mdi-close</v-icon>
          閉じる
        </v-btn>
        <v-btn v-if='isEdit' color='blue darken-2' dark :loading="loading" @click="updateFeePayStatus()">
          <v-icon class='pr-1'>mdi-content-save-outline</v-icon>
          更新する
        </v-btn>
        <v-btn v-else color='blue darken-2' dark :loading="loading" @click="createFeePayStatus()">
          <v-icon class='pr-1'>mdi-content-save-outline</v-icon>
          追加する
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { FeePayStatusUseCase } from '../useCase/feePayStatusUseCase';

export default {
  name: 'fee-pay-status-dialog',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    feePayStatus: {
      type: Object,
      default() {
        return {};
      },
    },
    uid: {
      type: String,
      required: true,
    },
  },
  computed: {
    userInfo() {
      return this.$store.getters.user;
    },
    isEdit() {
      return Object.keys(this.feePayStatus).length;
    },
    action() {
      return this.isEdit ? '更新' : '追加';
    },
  },
  watch: {
    dialog() {
      this.$emit('input', this.dialog);
    },
    value() {
      this.dialog = this.value;
      this.sendParam = Object.assign({}, this.feePayStatus);
    },
  },
  data() {
    return {
      dialog: false,
      loading: false,
      sendParam: {},
      formRules: {
        required: value => !!value || '必ず入力してください。',
        price: value => /^[0-9]{1,6}$/.test(value) || '半角数字６桁まで入力可能です。',
      },
    };
  },
  methods: {
    async createFeePayStatus() {
      this.loading = true;
      if (!this.$refs.form.validate()) {
        this.$store.commit('snackbar/open', { text: '入力内容に誤りがあります。', color: 'error' });
        this.loading = false;
        return;
      }
      this.sendParam.createdAt = new Date();
      this.sendParam.updatedAt = new Date();
      this.sendParam.updatedBy = this.userInfo;
      this.sendParam.feePaid = false;
      FeePayStatusUseCase.create(this.uid, this.sendParam)
        .then(() => {
          this.$emit('reload');
          this.loading = false;
          this.$store.commit('snackbar/open', { text: 'お支払い項目を追加しました。', color: 'success' });
          this.dialog = false;
        }).catch(() => {
          this.loading = false;
          this.$store.commit('snackbar/open', { text: 'お支払い項目を追加できませんでした。', color: 'error' });
        });
    },
    async updateFeePayStatus() {
      this.loading = true;
      if (!this.$refs.form.validate()) {
        this.$store.commit('snackbar/open', { text: '入力内容に誤りがあります。', color: 'error' });
        this.loading = false;
        return;
      }
      this.sendParam.createdAt = new Date(this.sendParam.createdAt);
      this.sendParam.updatedAt = new Date();
      this.sendParam.updatedBy = this.userInfo;
      FeePayStatusUseCase.update(this.uid, this.sendParam)
        .then(() => {
          this.$emit('reload');
          this.loading = false;
          this.$store.commit('snackbar/open', { text: 'お支払い項目を更新しました。', color: 'success' });
          this.dialog = false;
        }).catch(() => {
          this.loading = false;
          this.$store.commit('snackbar/open', { text: 'お支払い項目を更新できませんでした。', color: 'error' });
        });
    },
    close() {
      this.$emit('reload');
      this.$refs.form.resetValidation();
      this.dialog = false;
    },
  },
};
</script>
