<template>
  <v-dialog persistent scrollable v-model="dialog" max-width="640">
    <v-card>
      <v-card-title>
        <span v-if='complete' class='text-h5'>体験レッスンのお申し込みを受付けました</span>
        <span v-else class='text-h5'>体験レッスンご予約内容</span>
      </v-card-title>
      <v-card-text v-if='complete' class='pb-0'>
        <p>お申し込みいただきありがとうございます。</p>
        <p>ご登録のメールアドレスに受付完了のメールをお送りいたしました。</p>
      </v-card-text>
      <v-card-text v-else class='pb-0'>
        <v-container
          style="max-height: 800px"
        >
          <v-form ref='form' v-model='valid' lazy-validation :disabled='loading'>
            <v-row>
              <v-col cols='12' class='py-0'>
                <p class='mb-0 text--primary'>
                  予約日時
                </p>
                <v-text-field
                  v-model='reservationDate'
                  :value='reservationDate'
                  outlined
                  dense
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols='12' class='py-0'>
                <p class='mb-0 text--primary'>
                  参加人数
                  <span class='required-label'>
                    必須
                  </span>
                </p>
                <p
                  class='mb-1 text--secondary v-messages'
                  style='line-height: 1.5;'
                >
                  最大2名までお申し込みいただけます。
                </p>
                <v-row>
                  <v-col cols='12' class='d-flex justify-space-between pb-0'>
                    <v-select
                      v-model.number='trialData.guestsCount'
                      :items='[0, 1, 2]'
                      outlined
                      dense
                      type='email'
                      validate-on-blur
                      :rules='[formRules.required]'
                      label='右利き'
                      suffix='名'
                      class='mb-0'
                    ></v-select>
                    <p class='mx-2'></p>
                    <v-select
                      v-model.number='trialData.guestsCountLefty'
                      :items='leftyAcceptable'
                      outlined
                      dense
                      type='email'
                      validate-on-blur
                      :rules='[formRules.required]'
                      label='左利き'
                      suffix='名'
                    ></v-select>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols='12' class='py-0'>
                <p class='mb-0 text--primary'>
                  代表者氏名
                  <span class='required-label'>
                    必須
                  </span>
                </p>
                <p
                  class='mb-0 text--secondary v-messages'
                  style='line-height: 1.5;'
                >
                  フリガナは全角カタカナで入力してください。
                </p>
                <v-row>
                  <v-col cols='12' class='d-flex justify-space-between pb-0'>
                    <v-text-field
                      v-model='trialData.lastName'
                      outlined
                      dense
                      validate-on-blur
                      placeholder='姓：山田'
                      :rules='[formRules.required]'
                    ></v-text-field>
                    <p class='mx-2'></p>
                    <v-text-field
                      v-model='trialData.firstName'
                      outlined
                      dense
                      validate-on-blur
                      placeholder='名：太郎'
                      :rules='[formRules.required]'
                    ></v-text-field>
                  </v-col>
                  <v-col cols='12' class='d-flex justify-space-between pt-0'>
                    <v-text-field
                      v-model='trialData.lastNameKana'
                      outlined
                      dense
                      validate-on-blur
                      placeholder='セイ：ヤマダ'
                      :rules='[formRules.required, formRules.kana]'
                    ></v-text-field>
                    <p class='mx-2'></p>
                    <v-text-field
                      v-model='trialData.firstNameKana'
                      outlined
                      dense
                      validate-on-blur
                      placeholder='メイ：タロウ'
                      :rules='[formRules.required, formRules.kana]'
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols='12' class='py-0'>
                <p class='mb-0 text--primary'>
                  メールアドレス
                  <span class='required-label'>
                    必須
                  </span>
                </p>
                <p
                  class='mb-0 text--secondary v-messages'
                  style='line-height: 1.5;'
                >
                  ご予約確認メール等のご連絡先となります。
                </p>
                <v-text-field
                  v-model='trialData.email'
                  outlined
                  dense
                  type='email'
                  validate-on-blur
                  :rules='[formRules.required, formRules.email]'
                ></v-text-field>
              </v-col>
              <v-col cols='12' class='py-0'>
                <p class='mb-0 text--primary'>
                  電話番号
                  <span class='required-label'>
                    必須
                  </span>
                </p>
                <p
                  class='mb-0 text--secondary v-messages'
                  style='line-height: 1.5;'
                >
                  ご連絡の際に利用させていただく場合がございます。
                </p>
                <v-text-field
                  v-model='trialData.phonenumber'
                  outlined
                  dense
                  validate-on-blur
                  placeholder='例）090-1234-5678'
                  :rules='[formRules.tel, formRules.required]'
                ></v-text-field>
              </v-col>
              <v-col cols='12' class='py-0'>
                <p class='mb-0 text--primary'>
                  生年月日
                </p>
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="trialData.birthDate"
                      outlined
                      dense
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="trialData.birthDate"
                    locale="jp-ja"
                    :day-format="date => new Date(date).getDate()"
                    :active-picker.sync="activePicker"
                    :max="new Date().toISOString().substr(0, 10)"
                    min="1950-01-01"
                    @change="saveBirthday"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols='12' class='py-0'>
                <p class='mb-0 text--primary'>
                  性別
                </p>
                <v-radio-group
                  row
                  v-model='trialData.gender'
                >
                  <v-radio
                    label='女性'
                    value='女性'
                  ></v-radio>
                  <v-radio
                    label='男性'
                    value='男性'
                  ></v-radio>
                  <v-radio
                    label='その他'
                    value='その他'
                  ></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols='12' class='py-0'>
                <p class='mb-0 text--primary'>
                  ゴルフ歴
                </p>
                <p
                  class='mb-0 text--secondary v-messages'
                  style='line-height: 1.5;'
                >
                  半角数字で入力してください。
                </p>
                <v-text-field
                  v-model.number='trialData.golfHistory'
                  type='number'
                  outlined
                  dense
                  validate-on-blur
                  suffix='年'
                ></v-text-field>
              </v-col>
              <v-col cols='12' class='py-0'>
                <p class='mb-0 text--primary'>
                  ベストスコア
                </p>
                <p
                  class='mb-0 text--secondary v-messages'
                  style='line-height: 1.5;'
                >
                  半角数字で入力してください。
                </p>
                <v-text-field
                  v-model.number='trialData.bestScore'
                  type='number'
                  outlined
                  dense
                  validate-on-blur
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
          <v-row class='pb-3'>
            <v-spacer></v-spacer>
            <v-btn class='mr-2' color='grey darken-1' dark outlined @click='close' :disabled='loading'>
              <v-icon>mdi-close</v-icon>
              閉じる
            </v-btn>
            <v-btn color='blue darken-2' dark outlined @click='save' :loading='loading'>
              <v-icon>mdi-content-save-outline</v-icon>
              保存
            </v-btn>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions v-if='complete'>
        <v-spacer></v-spacer>
        <v-btn color='grey darken-1' dark outlined @click='allClose'>
          <v-icon class='pr-1'>mdi-close</v-icon>
          閉じる
        </v-btn>
        <!--
        <v-btn color='blue darken-2' dark outlined @click='save' :loading='loading'>
          <v-icon class='pr-1'>mdi-content-save-outline</v-icon>
          保存
        </v-btn>
        -->
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from 'moment';
import util from '../util';

export default {
  name: 'trial-entry-dialog',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    editedItem: {
      type: Object,
      required: true,
    },
    isLefty: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    dialog: false,
    valid: true,
    // 入力規則
    formRules: {
      required: value => value === 0 || !!value || '必ず入力してください。',
      requiredArray: value => (value || '').length > 0 || '1つ以上選択してください。',
      limit_length: value => (value || '').length <= 0 || '20文字以内で入力してください。',
      password: value => /^(?=.*\d)((?=.*[a-z])|(?=.*[A-Z]))[0-9a-zA-Z]{8,16}$/.test(value) || '半角数字と半角英字の2種類を含む8文字以上16文字以内で入力してください。',
      email: value => /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value) || 'メールアドレスの形式が正しくありません。',
      tel: value => /^(0\d{1,4}-\d{1,4}-\d{3,4})?$/.test(value) || '電話番号の形式が正しくありません。',
      kana: value => /^[ア-ン゛゜ァ-ォャ-ョー「」、]+$/.test(value) || '全角カタカナで入力してください。',
      number: value => /^[0-9]+$/.test(value) || '半角数字で入力してください。',
    },
    loading: false,
    trialData: {
      guestsCount: '',
      guestsCountLefty: '',
      lastName: '',
      firstName: '',
      lastNameKana: '',
      firstNameKana: '',
      email: '',
      phonenumber: '',
      birthDate: '',
      gender: '',
      golfHistory: '',
      bestScore: '',
    },
    menu: false,
    date: null,
    activePicker: null,
    pickerDate: '1980-1-1',
    complete: false,
  }),

  computed: {
    userInfo() {
      return this.$store.getters.user;
    },
    reservationDate() {
      return moment(this.editedItem.date.date + this.editedItem.date.time, 'YYYY-MM-DDHH:mm').locale('ja').format('YYYY年MM月DD日(dd) HH:mm ~');
    },
    leftyAcceptable() {
      let count = [0];
      if (this.editedItem.leftyPosition) {
        const leftyPosition = this.editedItem.leftyPosition.filter((v) => this.editedItem.emptyCategory.includes(v));
        if (leftyPosition.length) {
          count = leftyPosition.length >= 2 ? [0, 1, 2] : [0, 1];
        }
      }
      return count;
    },
  },

  watch: {
    async dialog() {
      this.$emit('input', this.dialog);
    },
    async value() {
      if (this.value) {
        this.trialData = Object.assign({}, this.trialData);
      } else {
        this.trialData = {
          guestsCount: '',
          guestsCountLefty: '',
          lastName: '',
          firstName: '',
          lastNameKana: '',
          firstNameKana: '',
          email: '',
          phonenumber: '',
          birthDate: '',
          gender: '',
          golfHistory: '',
          bestScore: '',
        };
      }
      this.dialog = this.value;
    },
    menu(val) {
      if (val) {
        this.$nextTick(() => {
          this.activePicker = 'YEAR';
        });
      }
    },
  },

  methods: {
    saveBirthday(date) {
      this.$refs.menu.save(date);
      this.pickerDate = date;
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.$refs.form.resetValidation();
      });
    },
    allClose() {
      this.close();
      this.complete = false;
    },
    mail() {
      const param = {
        shop: this.editedItem.shop,
        user: this.trialData,
        date: this.editedItem.date.date,
        time: this.editedItem.date.time,
        type: this.editedItem.type,
        category: this.editedItem.category,
        instructor: this.editedItem.instructor,
      };
      util.callCloudFunction('sendMail', param);
    },
    async save() {
      if (!this.$refs.form.validate()) {
        this.$store.commit('snackbar/open', { text: '入力内容に誤りがある、もしくは未入力の項目があります。', color: 'error' });
        return;
      }
      if ((this.trialData.guestsCount + this.trialData.guestsCountLefty) <= 0) {
        this.$store.commit('snackbar/open', { text: '参加人数を設定してください。', color: 'error' });
        return;
      }
      if ((this.trialData.guestsCount + this.trialData.guestsCountLefty) > 2) {
        this.$store.commit('snackbar/open', { text: '参加人数は最大２名までです。', color: 'error' });
        return;
      }
      this.loading = true;
      const param = {
        shop: this.editedItem.shop,
        user: this.trialData,
        date: this.editedItem.date.date,
        time: this.editedItem.date.time,
        type: this.editedItem.type,
        category: this.editedItem.category,
        instructor: this.editedItem.instructor,
      };
      await util.callCloudFunction('addTrialReservation', param)
        .then(() => {
          // this.$store.commit('snackbar/open', { text: '予約を正常に受付けました。', color: 'success' });
          util.callCloudFunction('sendMail', param);
          this.complete = true;
        }).catch(error => {
          this.$store.commit('snackbar/open', { text: error, color: 'error' });
        });
      this.loading = false;
      // this.close();
    },
  },
};
</script>
<style>
input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.v-messages__message {
  line-height: 13px !important;
}
</style>
