export class FeePayStatus {
  #id;
  set id(value) {
    if (typeof value === 'undefined' || value === null) {
      this.#id = '';
    } else {
      this.#id = value;
    }
  }
  get id() {
    return this.#id;
  }

  #name;
  set name(value) {
    if (!value) {
      throw new Error('"name" is a required field.');
    }
    this.#name = value;
  }
  get name() {
    return this.#name;
  }

  #price;
  set price(value) {
    if (typeof value !== 'number') {
      throw new Error('"price" must be an number.');
    }
    this.#price = value;
  }
  get price() {
    return this.#price;
  }

  #memo;
  set memo(value) {
    if (typeof value === 'undefined' || value === null) {
      this.#memo = '';
    } else {
      this.#memo = value;
    }
  }
  get memo() {
    return this.#memo;
  }

  #createdAt;
  set createdAt(value) {
    const isInvalidDate = (date) => Number.isNaN(date.getDate());
    if (isInvalidDate(new Date(value))) {
      throw new Error('"createdAt" must be an Date.');
    }
    this.#createdAt = value;
  }
  get createdAt() {
    return this.#createdAt;
  }

  #updatedAt;
  set updatedAt(value) {
    const isInvalidDate = (date) => Number.isNaN(date.getDate());
    if (isInvalidDate(new Date(value))) {
      throw new Error('"updatedAt" must be an Date.');
    }
    this.#updatedAt = value;
  }
  get updatedAt() {
    return this.#updatedAt;
  }

  #updatedBy = {
    uid: '',
    name: '',
    nameKana: '',
  };
  set updatedBy(value) {
    try {
      this.#updatedBy.uid = value.uid;
    } catch {
      throw new Error('"updatedBy.uid" is a required field.');
    }
    try {
      this.#updatedBy.name = value.name;
    } catch {
      throw new Error('"updatedBy.name" is a required field.');
    }
    try {
      this.#updatedBy.nameKana = value.nameKana;
    } catch {
      throw new Error('"updatedBy.nameKana" is a required field.');
    }
  }
  get updatedBy() {
    return this.#updatedBy;
  }

  #feePaid;
  set feePaid(value) {
    if (typeof value !== 'boolean') {
      throw new Error('"feePaid" must be an boolean.');
    }
    this.#feePaid = value;
  }
  get feePaid() {
    return this.#feePaid;
  }

  constructor(feePayStatus) {
    this.id = feePayStatus.id;
    this.name = feePayStatus.name;
    this.price = feePayStatus.price;
    this.memo = feePayStatus.memo;
    this.createdAt = feePayStatus.createdAt;
    this.updatedAt = feePayStatus.updatedAt;
    this.updatedBy = feePayStatus.updatedBy;
    this.feePaid = feePayStatus.feePaid;
  }

  toObject() {
    return {
      id: this.id,
      name: this.name,
      price: this.price,
      memo: this.memo,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
      updatedBy: this.updatedBy,
      feePaid: this.feePaid,
    };
  }
}
