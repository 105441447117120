import firebae from '../config';

export class FeePayStatusCloudFunction {
  static create(uid, feePayStatus) {
    const data = { uid, feePayStatus };
    return firebae.callCloudFunction('createFeePayStatus', data);
  }
  static update(uid, feePayStatus) {
    const data = { uid, feePayStatus };
    return firebae.callCloudFunction('updateFeePayStatus', data);
  }
  static get(uid) {
    const data = { uid };
    return firebae.callCloudFunction('getFeePayStatus', data);
  }
}
