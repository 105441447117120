<template>
  <v-dialog persistent scrollable v-model="dialog" max-width="1280">
    <v-card>
      <v-card-title>
        <span class='text-h5'>レッスン内容の編集</span>
      </v-card-title>
      <v-card-text class='pb-0'>
        <v-container>
          <v-form ref="form" lazy-validation @submit.prevent>
            <v-card-text>
              <h3 class="my-3">{{ editItem.index }}回目</h3>
              <p class='mb-0 text--primary'>
              日付
              </p>
              <v-menu
                ref="menu"
                v-model="menu"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    outlined
                    dense
                    v-model="editItem.date"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="editItem.date"
                  locale="ja-jp"
                  :day-format="date => new Date(date).getDate()"
                  no-title
                >
                </v-date-picker>
              </v-menu>
              <v-row>
                <v-col cols='12' class='py-0'>
                  <p class='mb-0 text--primary'>
                  担当コーチ
                  </p>
                  <v-autocomplete
                    v-model='editItem.instructor'
                    :items='instructorAll'
                    item-text='name'
                    outlined
                    dense
                    return-object
                  ></v-autocomplete>
                </v-col>
                <v-col cols='12' class='pb-0'>
                  <p class='mb-0 text--primary'>
                  使用クラブ
                  </p>
                  <v-divider></v-divider>
                  <v-col class='my-0 py-0 d-flex'>
                    <v-checkbox
                      class="mx-2"
                      v-for="n in clubs"
                      :key="n"
                      v-model="editItem.club"
                      :label="n"
                      :value="n"
                    ></v-checkbox>
                  </v-col>
                </v-col>
                <v-col cols='12' class='py-0'>
                  <p class='mb-0 text--primary'>
                  取り組んだこと
                  </p>
                  <v-divider></v-divider>
                  <v-col class='my-0 py-0 d-flex'>
                    <v-checkbox
                      class="mx-2"
                      v-for="n in lessonList"
                      :key="n"
                      v-model="editItem.lesson"
                      :label="n"
                      :value="n"
                    ></v-checkbox>
                  </v-col>
                </v-col>
                <v-col cols='12' class='py-0'>
                  <p class='mb-0 text--primary'>
                  メモ
                  </p>
                  <v-textarea
                    v-model='editItem.memo'
                    outlined
                    dense
                    validate-on-blur
                    placeholder='次回○○予定など'
                    rows=2
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-card-text>
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color='grey darken-1' dark outlined :disabled="loading" @click="close">
          <v-icon class='pr-1'>mdi-close</v-icon>
          閉じる
        </v-btn>
        <v-btn color='blue darken-2' dark outlined :loading="loading" @click="editKarte">
          <v-icon class='pr-1'>mdi-content-save-outline</v-icon>
          保存
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import util from '../util';

export default {
  name: 'karte-history-dialog',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    karte: {
      type: Object,
      required: true,
    },
    editItem: {
      type: Object,
      required: true,
    },
    karteId: {
      tyep: String,
      required: true,
    },
    uid: {
      type: String,
      required: true,
    },
  },
  computed: {
    userInfo() {
      return this.$store.getters.user;
    },
  },
  watch: {
    async dialog() {
      if (this.dialog) {
        this.instructorAll = await util.callCloudFunction('getUser', { roll: 'instructor', status: 'all' });
      }
      this.$emit('input', this.dialog);
    },
    value() {
      this.dialog = this.value;
    },
  },
  data() {
    return {
      isEdit: false,
      settings: [],
      clubs: ['P', '7', 'UT', 'FW', 'D', 'Pt'],
      lessonList: ['G', 'A', 'S/U', 'S/P', 'T/R', 'C/F', 'O/F', 'A/R', 'B/S', 'T/B', 'D/S', 'F/S', 'F', 'S/T', 'I/P', 'W/S'],
      dialog: false,
      loading: false,
      instructorAll: [],
      menu: false,
      backup: {},
    };
  },
  methods: {
    async editKarte() {
      this.loading = true;
      await util.callCloudFunction('editKarteHistory', { uid: this.uid, karteId: this.karteId, doc: this.karte.doc });
      this.loading = false;
      this.$store.commit('snackbar/open', { text: `${this.editItem.index}回目のレッスン内容を編集しました。`, color: 'success' });
      this.$emit('reload');
      this.dialog = false;
    },
    converArray(object) {
      const array = [];
      array.push(object);
      return array;
    },
    close() {
      this.$emit('reload');
      this.dialog = false;
    },
  },
};
</script>
<style>
.v-data-table tr:hover td {
    background-color: white;
}
</style>
