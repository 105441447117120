var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mt-2 mb-4",attrs:{"flat":""}},[_c('v-card-text',[_c('p',{staticClass:"mb-0 text--primary"},[_vm._v("月額会費以外のお支払いがある場合は、こちらで管理してください。")]),_c('v-row',[_c('v-spacer'),_c('v-btn',{staticClass:"ml-auto ma-2",attrs:{"outlined":"","color":"grey darken-2"},on:{"click":_vm.reload}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-reload")]),_vm._v("更新 ")],1),_c('v-btn',{staticClass:"ml-auto ma-2",attrs:{"outlined":"","color":"grey darken-2"},on:{"click":_vm.addDialog}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v("項目を追加 ")],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.list,"items-per-page":15,"hide-default-footer":"","item-key":"index","loading":_vm.loading,"loading-text":"読み込み中です","no-data-text":"対象のデータがありません"},scopedSlots:_vm._u([{key:"item.price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatPrice(item.price))+" ")]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatTime(item.createdAt))+" ")]}},{key:"item.updatedAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatTime(item.updatedAt))+" ")]}},{key:"item.updatedBy",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.updatedBy.name)+" "),(item.updatedBy.nameKana)?_c('p',{staticClass:"mb-0 text--secondary v-messages",staticStyle:{"line-height":"1.5"}},[_vm._v(" ("+_vm._s(item.updatedBy.nameKana)+") ")]):_vm._e()]}},{key:"item.feePaid",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatStatus(item.feePaid))+" ")]}},{key:"item.edit",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.editDialog(item)}}},on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v("詳細/編集")])])]}}])})],1),_c('fee-pay-status-dialog',{attrs:{"uid":_vm.user.uid,"feePayStatus":_vm.editItem},on:{"reload":function($event){return _vm.reload()}},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }