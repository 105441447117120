<template>
  <v-card flat class="mt-2 mb-4">
    <v-card-text>
      <v-row>
        <span class="px-2 text-caption">入会日：{{ userCreationTime }}</span>
        <span class="px-2 text-caption">入会状況： {{ user.membership }}</span>
      </v-row>
      <v-row class="pb-2 mb-2">
        <span class='text-h6 px-2'>{{ user.name }} ({{ user.nameKana }})</span>
        <v-spacer></v-spacer>
        <v-btn outlined color="grey darken-2" @click="infoDialog=true">
          <v-icon left>mdi-pencil</v-icon>基本情報を編集する
        </v-btn>
      </v-row>
      <v-divider></v-divider>
      <transition name="fade" mode="out-in">
        <v-skeleton-loader
          v-if="loading"
          type="table-heading, list-item-two-line, image"
        ></v-skeleton-loader>
        <v-card v-else class='mt-3 pt-2'>
          <v-card-text class="pb-2">
            <v-row>
              <v-col>
                <p class="mb-0">ゴルフ歴</p>
                <p class="font-weight-bold">{{ karteInfo.golfHistory }}</p>
              </v-col>
              <v-col>
                <p class="mb-0">平均スコア</p>
                <p class="font-weight-bold">{{ karteInfo.averageScore }}</p>
              </v-col>
            </v-row>
            <p class='mb-0'>入会目的</p>
            <p class="font-weight-bold">{{ karteInfo.purpose }}</p>
            <p class='mb-0'>伝達事項</p>
            <p class="font-weight-bold">{{ karteInfo.message }}</p>
            <p class='mb-0'>病気・ケガ</p>
            <p class="font-weight-bold">{{ karteInfo.noi }}</p>
            <p class='mb-0'>短期目標</p>
            <p class="font-weight-bold">{{ karteInfo.stg }}</p>
            <p class='mb-0'>長期目標</p>
            <p class="font-weight-bold">{{ karteInfo.ltg }}</p>
            <p class='mb-1'>スクール案内について</p>
            <v-divider></v-divider>
            <p class="font-weight-bold ma-1">
              <v-icon v-if="karteInfo.guidance[0]">mdi-checkbox-outline</v-icon>
              <v-icon v-else>mdi-checkbox-blank-outline</v-icon>
              施設のマナーやルール
            </p>
            <v-divider></v-divider>
            <p class="font-weight-bold ma-1">
              <v-icon v-if="karteInfo.guidance[1]">mdi-checkbox-outline</v-icon>
              <v-icon v-else>mdi-checkbox-blank-outline</v-icon>
              シュミレーターの見方や使い方
            </p>
            <v-divider></v-divider>
            <p class="font-weight-bold ma-1">
              <v-icon v-if="karteInfo.guidance[2]">mdi-checkbox-outline</v-icon>
              <v-icon v-else>mdi-checkbox-blank-outline</v-icon>
              ラウンドレッスンや家庭教師の案内
            </p>
          </v-card-text>
        </v-card>
      </transition>
    </v-card-text>
    <karte-info-dialog v-model="infoDialog" :karteInfo="karteInfo" @reload="reload"></karte-info-dialog>
  </v-card>
</template>

<script>
import moment from 'moment';
import util from '../util';
import KarteInfoDialog from './KarteInfoDialog';

export default {
  name: 'karte-info',
  components: {
    KarteInfoDialog,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    user: {
      type: Object,
      required: true,
    },
  },
  async mounted() {
    this.reload();
  },
  watch: {
    async user() {
      this.loading = true;
      this.karteInfo = {
        golfHistory: '',
        averageScore: '',
        purpose: '',
        message: '',
        noi: '',
        stg: '',
        ltg: '',
        guidance: [false, false, false],
      };
      if (this.user.uid) {
        this.reload();
      }
    },
  },
  computed: {
    userInfo() {
      return this.$store.getters.user;
    },
    userCreationTime() {
      return moment(this.user.userCreationTime).format('YYYY年 MM月DD日');
    },
  },
  data: () => ({
    karteInfo: {
      golfHistory: '',
      averageScore: '',
      purpose: '',
      message: '',
      noi: '',
      stg: '',
      ltg: '',
      guidance: [false, false, false],
    },
    isEdit: false,
    settings: [],
    // dialog: false,
    infoDialog: false,
    loading: false,
  }),
  methods: {
    async reload() {
      this.loading = true;
      const info = await util.callCloudFunction('getKarteInfo', { uid: this.user.uid })
        .catch(error => {
          this.$store.commit('snackbar/open', { text: error, color: 'error' });
        });
      this.karteInfo = Object.assign({}, this.karteInfo, info);
      this.karteInfo.uid = this.user.uid;
      this.loading = false;
    },
    async save() {
      const param = {
        uid: this.user.uid,
        karteInfo: this.karteInfo,
      };
      await util.callCloudFunction('editKarteInfo', param)
        .then(() => {
          this.$store.commit('snackbar/open', { text: '基本情報を編集しました。', color: 'success' });
        }).catch(error => {
          this.$store.commit('snackbar/open', { text: error, color: 'error' });
        });
    },
  },
};
</script>
