import { FeePayStatusRepository } from '../domain/service/feePayStatusRepository';

export class FeePayStatusUseCase {
  static create(uid, param) {
    return FeePayStatusRepository.create(uid, param);
  }

  static getByUserId(uid) {
    return FeePayStatusRepository.get(uid);
  }

  static delete(param) {
    return FeePayStatusRepository.delete(param);
  }

  static update(uid, param) {
    return FeePayStatusRepository.update(uid, param);
  }
}
