<template>
  <v-menu
    ref="menu"
    v-model="menu"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        class="ma-2"
        outlined
        dense
        v-model="month"
        label="表示月を選択"
        readonly
        v-bind="attrs"
        v-on="on"
      ></v-text-field>
    </template>
    <v-date-picker
      id='picker-month'
      v-model="month"
      locale="ja-jp"
      type="month"
      no-title
    >
    </v-date-picker>
  </v-menu>
</template>

<script>
export default {
  name: 'Datepicker',
  props: ['selectMonth'],
  data: () => ({
    menu: false,
  }),
  computed: {
    month: {
      get() {
        return this.selectMonth;
      },
      set(newVal) {
        this.$emit('input', newVal);
      },
    },
  },
};
</script>
