<template>
  <v-dialog persistent scrollable v-model="karteDialog" max-width="1280">
    <v-card>
      <v-card-title>
        <span class='text-h5'>カルテ</span>
        <v-spacer></v-spacer>
        <v-btn outlined color="grey darken-2" @click="karteDialog=false">
          <v-icon left>mdi-close</v-icon>閉じる
        </v-btn>
        <v-tabs
        :color="'purple'"
        :slider-color="'purple'"
        v-model="tabs"
        >
          <v-tab href="#info">基本情報</v-tab>
          <v-tab href="#history">レッスン履歴</v-tab>
          <v-tab href="#usageHistory">利用履歴</v-tab>
          <v-tab href="#payment">その他お支払い</v-tab>
        </v-tabs>
      </v-card-title>
      <v-card-text>
        <v-tabs-items v-model="tabs">
          <v-tab-item value="info">
            <karte-info
              :user="user"
            ></karte-info>
          </v-tab-item>
          <v-tab-item value="history">
            <karte-history
              :user="user"
            ></karte-history>
          </v-tab-item>
          <v-tab-item value="usageHistory">
            <attendance-history :userId='user.uid' :isKarte='true'></attendance-history>
          </v-tab-item>
          <v-tab-item value="payment">
            <fee-pay-status
              :user="user"
            ></fee-pay-status>
          </v-tab-item>
        <!-- <v-tabs-items v-model="tabs">
          <v-tab-item value="info">
          </v-tab-item>
          <v-tab-item value="lesson">
            <p>レッスン</p>
          </v-tab-item> -->
        </v-tabs-items>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import KarteInfo from './KarteInfo';
import KarteHistory from './KarteHistory';
import AttendanceHistory from '../views/AttendanceHistory';
import FeePayStatus from './FeePayStatus';

export default {
  name: 'karte-dialog',
  components: {
    KarteInfo,
    KarteHistory,
    AttendanceHistory,
    FeePayStatus,
  },
  props: {
    content: {
      type: Object,
      default() {
        return {};
      },
    },
    copyFrom: {
      type: Object,
      required: false,
    },
    page: {
      type: Object,
      default: null,
    },
    value: {
      type: Boolean,
      default: false,
    },
    user: {
      type: Object,
      required: true,
    },
  },
  watch: {
    karteDialog() {
      this.$emit('input', this.karteDialog);
      this.tabs = 'info';
    },
    async value() {
      this.karteDialog = this.value;
    },
  },
  data() {
    return {
      settings: [],
      tabs: null,
      karteDialog: false,
      expiration: null,
      loading: {
        preview: false,
        submit: false,
      },
      isEdit: false,
      valid: true,
    };
  },
};
</script>
