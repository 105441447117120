<template>
  <div class='pa-6' style="height:95%">
    <h2>予約管理</h2>
    <v-sheet
      tile
      height="60"
      class="d-flex"
      >
      <v-btn v-if='isMonth' text class="mt-3" @click="prevMonth()">
        <v-icon>mdi-chevron-left</v-icon>
        前月
      </v-btn>
      <v-btn v-else text class="mt-3" @click="prevDay()">
        <v-icon>mdi-chevron-left</v-icon>
        前日
      </v-btn>
      <v-spacer></v-spacer>
      <v-select
        v-model="selectShop"
        item-text="name"
        item-value="id"
        :items="shops"
        dense
        outlined
        hide-details
        label="店舗"
        class="ma-2"
        return-object
      ></v-select>
      <DatepickerMonth v-if='isMonth' :selectMonth="selectMonth" @input="selectMonth = $event"></DatepickerMonth>
      <Datepicker v-else :selectDay='selectDay' @input='selectDay = $event'></Datepicker>
      <v-btn-toggle
        dence
        color='blue'
        class='align-end'
        v-model="selectViewType"
      >
        <v-btn v-for="view in viewTypes"
          :key="view.text"
          :value="view.value"
          small
          class='mb-3'
        >
        {{ view.text }}
        </v-btn>
      </v-btn-toggle>
      <v-spacer></v-spacer>
      <v-btn v-if='isMonth' text class="mt-3" @click="nextMonth()">
        翌月
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
      <v-btn v-else text class="mt-3" @click="nextDay()">
        翌日
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
    </v-sheet>
    <v-sheet v-if='!isMonth' dense class='text-caption mb-2'>
      <v-icon small :color='SHIFT_TYPE.lesson.color'>mdi-square-rounded</v-icon>・・レッスン
      <v-icon class='ml-3' small :color='SHIFT_TYPE.free.color'>mdi-square-rounded</v-icon>・・フリーレンジ
      <v-icon class='ml-3' small :color='SHIFT_TYPE.trial.color'>mdi-square-rounded</v-icon>・・体験
      <v-icon class='ml-3' small :color='SHIFT_TYPE.empty.color'>mdi-square-rounded</v-icon>・・インストラクター空き
      <v-icon class='ml-3' small :color='SHIFT_TYPE.maintenance.color'>mdi-square-rounded</v-icon>・・メンテナンス
    </v-sheet>
    <v-sheet v-show='isMonth' id='calendar' style="height:90%">
      <transition name="fade" mode="out-in">
      <v-skeleton-loader
        v-if="loading"
        type="table-tbody, table-tbody"
      ></v-skeleton-loader>
      <v-calendar
        v-else
        locale="ja-jp"
        ref="calendar"
        v-model="focus"
        :events="events"
        type="month"
        @click:date='showCategory'
        @click:day='showCategory'
        @click:event='event_showCategory'
        :day-format="timestamp => new Date(timestamp.date).getDate()"
        :month-format="timestamp => (new Date(timestamp.date).getMonth() + 1) + ' /'"
      ></v-calendar>
      </transition>
    </v-sheet>
    <v-sheet v-show='!isMonth' id='category'>
      <transition name="fade" mode="out-in" @enter="adjustmentCategory">
      <v-skeleton-loader
        v-if="loading"
        type="table-tbody, table-tbody"
      ></v-skeleton-loader>
      <v-calendar
        v-else
        locale="ja-jp"
        ref="calendar"
        v-model="focus"
        :events="dailyEvents"
        type="category"
        :category-days='1'
        :categories="categories"
        category-show-all
        :first-interval='firstInterval'
        :interval-count='intervalCount + 1'
        interval-height=60
        @click:time-category="open"
        @mousedown:event="open"
        :day-format="timestamp => (new Date(timestamp.date).getMonth() + 1) + ' /' + new Date(timestamp.date).getDate()"
      >
      </v-calendar>
      </transition>
    </v-sheet>
    <!-- dialog -->
    <v-dialog
      v-model='dialog'
      max-width='500px'
      persistent
    >
    <v-card v-if='(isNoShift || isEmpty) && !isBefore'>
      <v-card-title>
        <span class="headline">{{ formTitle }}</span>
      </v-card-title>
      <v-card-text>
        <v-alert
          type="error"
          icon="mdi-alert-outline"
          text
          dense
          border="left"
        >
          確定されたシフトが登録されていないため予約できません。
        </v-alert>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="grey darken-1" dark outlined @click='close'>
          <v-icon class='pr-1'>mdi-close</v-icon>
          閉じる
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-card v-else-if='!isExist && isBefore'>
        <v-card-title>
          <span class="headline">{{ formTitle }}</span>
        </v-card-title>
        <v-card-text>
          <v-alert
            type="error"
            icon="mdi-alert-outline"
            text
            dense
            border="left"
          >
            過去の日時は予約できません。
          </v-alert>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey darken-1" dark outlined @click='close'>
            <v-icon class='pr-1'>mdi-close</v-icon>
            閉じる
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-form ref="form" v-else>
        <v-card>
          <v-card-title>
            <span class="headline">{{ formTitle }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols='12' class='py-0'>
                  <p class='mb-0 text--primary'>
                    予約日時
                  </p>
                  <v-text-field
                    v-model='param.date.text'
                    outlined
                    dense
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols='12' class='py-0' v-if='isExist && param.type !== "体験" && param.type !== "メンテナンス"'>
                  <p class='mb-0 text--primary'>
                    会費ペイID
                  </p>
                  <v-text-field
                    v-model='param.member.payid'
                    outlined
                    dense
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols='12' class='py-0'>
                  <p class='mb-0 text--primary'>
                    種別
                  </p>
                  <v-text-field
                    v-if='isExist'
                    v-model='param.type'
                    outlined
                    dense
                    readonly
                  ></v-text-field>
                  <v-select
                    v-else
                    v-model='param.type'
                    :items="freeOnly ? freeTypes : types"
                    outlined
                    dense
                  ></v-select>
                </v-col>
                <v-col v-if='param.type !== "体験" && param.type !== "メンテナンス"' cols='12' class='py-0'>
                  <p class='mb-0 text--primary'>
                    会員名
                  </p>
                  <v-combobox
                    v-model='param.member'
                    :items='selectMembers'
                    item-text='name'
                    outlined
                    dense
                    :readonly='isExist'
                  ></v-combobox>
                </v-col>
                <v-col cols='12' class='py-0' v-if='param.type !== typeFree && param.type !== "メンテナンス"'>
                  <p class='mb-0 text--primary'>
                    担当インストラクター
                  </p>
                  <v-text-field
                    v-model='param.instructor.name'
                    outlined
                    dense
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols='12' class='py-0'>
                  <p class='mb-0 text--primary'>
                    利用打席
                  </p>
                  <v-text-field
                    v-model='param.category'
                    outlined
                    dense
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols='12' class='py-0' v-if='param.type === "体験" || param.type === "メンテナンス"'>
                  <p class='mb-0 text--primary'>
                    備考
                  </p>
                  <v-textarea
                    v-model='param.note'
                    outlined
                    :readonly='isExist'
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="grey darken-1" dark outlined @click='dialog = false' :disabled="submit">
              <v-icon class='pr-1'>mdi-close</v-icon>
              閉じる
            </v-btn>
            <v-btn v-if='!isExist && param.type !== "体験"' color="blue darken-2" dark outlined @click="addReservation" :loading="submit">
              <v-icon class='pr-1'>mdi-content-save-outline</v-icon>
              登録
            </v-btn>
            <v-btn v-if='!isExist && param.type === "体験"' color="blue darken-2" dark outlined @click="openTrialDialog(param.date.text, param.lefty)" :loading="submit">
              <v-icon class='pr-1'>mdi-content-save-outline</v-icon>
              参加者の情報を登録
            </v-btn>
            <v-btn v-if='isExist && param.type === "体験"' color="blue darken-2" dark outlined @click="trialInfoDialog = true" :loading="submit">
              <v-icon class='pr-1'>mdi-content-save-outline</v-icon>
              参加者の情報を確認
            </v-btn>
            <v-btn v-if='isExist && param.type !== "体験" && param.type !== "メンテナンス"' color="blue darken-2" dark outlined @click="karteDialog = true" :loading="submit">
              <v-icon class='pr-1'>mdi-pencil</v-icon>
              会員のカルテを確認
            </v-btn>
            <v-btn v-if='isExist && !isBefore' color="red darken-2" dark outlined @click="confirm = true" :loading="submit">
              <v-icon class='pr-1'>mdi-trash-can-outline</v-icon>
              削除
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <v-dialog v-model='confirm' max-width='500px'>
      <v-card>
        <v-card-title>
          <span class="headline">予約の取り消し</span>
        </v-card-title>
        <v-card-text>
          <div>予約の内容が完全に削除されます。よろしいですか？</div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey darken-1" dark outlined @click='confirm = false' :disabled="submit">
            <v-icon class='pr-1'>mdi-close</v-icon>
            閉じる
          </v-btn>
          <v-btn color="red darken-2" dark outlined @click="deleteReservation" :loading="submit">
            <v-icon class='pr-1'>mdi-trash-can-outline</v-icon>
            削除
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <karte-dialog v-model="karteDialog" :user="param.member"></karte-dialog>
    <trial-entry-dialog v-model='trialDialog' :editedItem='param' :isLefty='isLefty'></trial-entry-dialog>
    <trial-info-dialog v-model='trialInfoDialog' :editedItem='param'></trial-info-dialog>
  </div>
</template>

<script>
import moment from 'moment';
import util from '../util';
import config from '../mixins/config';
import DatepickerMonth from '../components/DatepickerMonth';
import Datepicker from '../components/Datepicker';
import KarteDialog from '../components/KarteDialog';
import TrialEntryDialog from '../components/TrialEntryDialog';
import TrialInfoDialog from '../components/TrialInfoDialog';

export default {
  components: {
    DatepickerMonth,
    Datepicker,
    KarteDialog,
    TrialEntryDialog,
    TrialInfoDialog,
  },
  mixins: [config],
  data: () => ({
    typeFree: 'フリーレンジ',
    types: ['レッスン', 'フリーレンジ', 'メンテナンス'],
    freeTypes: ['フリーレンジ', 'メンテナンス'],
    selectDay: moment(new Date()).format('YYYY-MM-DD'),
    selectMonth: moment(new Date()).format('YYYY-MM'),
    focus: moment(new Date()).format('YYYY-MM-DD'),
    selectViewType: 'category',
    viewTypes: [{ value: 'month', text: 'シフト表示' }, { value: 'category', text: '予約表示' }],
    shops: [],
    events: [],
    dailyEvents: [],
    categories: [],
    dialog: false,
    karteDialog: false,
    trialDialog: false,
    trialInfoDialog: false,
    confirm: false,
    event: {},
    param: {
      date: '',
      member: { name: '' },
      type: '',
      instructor: {},
      note: '',
    },
    isNoShift: false,
    isBefore: false,
    isLefty: false,
    freeOnly: false,
    isExist: false,
    isEmpty: false,
    selectShop: '',
    allMembers: [],
    selectMembers: [],
    rightMembers: [],
    leftMembers: [],
    loading: false,
    submit: false,
    reservationDate: '',
    // selectMember: {},
    isHoliday: false,
  }),
  computed: {
    isMonth() {
      return this.selectViewType === 'month';
    },
    firstInterval() {
      return this.isHoliday ? this.selectShop.holidayOpenTime : this.selectShop.openTime;
    },
    intervalCount() {
      return this.isHoliday ? this.selectShop.holidayCloseTime - this.selectShop.holidayOpenTime : this.selectShop.closeTime - this.selectShop.openTime;
    },
    formTitle() {
      return this.isExist ? '予約詳細' : '新規予約';
    },
    // selectMonth() {
    //   return moment(this.selectDay).format('YYYY-MM');
    // },
  },
  async mounted() {
    this.loading = true;
    await this.getShopName();
    await this.updateDailyEvent();
    this.allMembers = await util.callCloudFunction('getUser', { roll: 'member', status: 'all' });
    this.rightMembers = this.allMembers.filter((v) => !v.lefty);
    this.leftMembers = this.allMembers.filter((v) => v.lefty);
    this.updateShift();
  },
  watch: {
    async selectMonth() {
      this.focus = moment(this.selectMonth, 'YYYY-MM').format('YYYY-MM-DD');
      await this.updateShift();
    },
    async selectDay() {
      await this.updateDailyEvent();
      this.selectMonth = moment(this.selectDay, 'YYYY-MM-DD').format('YYYY-MM');
    },
    async selectShop() {
      await this.updateShift();
      await this.updateDailyEvent();
    },
    async selectViewType() {
      await this.updateShift();
    },
    // events() {
    //   this.$nextTick(() => this.adjustment());
    // },
    // dailyEvents() {
    //   this.$nextTick(() => this.adjustmentCategory());
    // },
    dialog() {
      if (!this.dialog) {
        this.param = {
          member: {
            name: '',
            payid: '',
          },
          instructor: { name: '' },
          type: '',
          date: {
            text: '',
            date: '',
            time: '',
          },
          category: '',
          start: '',
          end: '',
        };
        this.isExist = false;
        this.freeOnly = false;
      }
    },
  },
  methods: {
    open({ event, date, hour, category }) {
      if (this.dialog) return;
      if (event && (event.name !== 'レフティ' && event.name !== 'レフティ+')) {
        // 予約あり
        if (event.name !== '空き' && event.name !== '空き(レフティ)' && event.name !== '空き(レフティ+)') {
          this.isExist = true;
          this.setParam(event);
        } else {
        // 予約なし（レッスン or 体験）
          this.param.category = event.category;
          this.param.instructor = {
            name: event.instructor.name,
            nameKana: event.instructor.nameKana,
            uid: event.instructor.uid,
          };
          this.param.date = {
            text: moment(event.start, 'YYYY-MM-DD HH:mm').locale('ja').format('YYYY年MM月DD日(dd) HH:mm ~'),
            date: moment(event.start).format('YYYY-MM-DD'),
            time: moment(event.start).format('HH:mm'),
          };
          if (event.name === '空き') {
            this.selectMembers = this.rightMembers;
          } else {
            this.selectMembers = event.name === '空き(レフティ)' ? this.leftMembers : this.allMembers;
          }
        }
        this.isNoShift = false;
      } else if ((event && (event.name === 'レフティ' || event.name === 'レフティ+')) || this.events.some(v => v.start === date)) {
        // 予約なし（フリー）
        this.param.category = event ? event.category : category;
        this.param.type = this.typeFree;
        this.param.instructor = {
          name: '',
          nameKana: '',
          uid: '',
        };
        if (event) {
          this.param.date = {
            text: moment(event.start).locale('ja').format('YYYY年MM月DD日(dd) HH:mm ~'),
            date: moment(event.start).format('YYYY-MM-DD'),
            time: moment(event.start).format('HH:mm'),
          };
          this.selectMembers = event.name === 'レフティ' ? this.leftMembers : this.allMembers;
        } else {
          this.param.date = {
            text: moment((date + hour), 'YYYY-MM-DDHH').locale('ja').format('YYYY年MM月DD日(dd) HH:mm ~'),
            date: date,
            time: moment(hour, 'HH').format('HH:mm'),
          };
          this.selectMembers = this.rightMembers;
        }
        this.isNoShift = false;
        this.freeOnly = true;
      } else {
        // シフトなし
        this.isNoShift = true;
      }
      this.dialog = true;
    },
    openTrialDialog(reservationDate, isLefty) {
      this.trialDialog = true;
      this.isLefty = isLefty;
      this.reservationDate = reservationDate;
    },
    setParam(event) {
      this.param = Object.assign({}, this.param, event);
      this.param.date = {
        text: moment(event.start).locale('ja').format('YYYY年MM月DD日(dd) HH:mm ~'),
        date: moment(event.start).format('YYYY-MM-DD'),
        time: moment(event.start).format('HH:mm'),
      };
    },
    async showCategory({ date }) {
      this.selectDay = date;
      this.selectViewType = 'category';
    },
    async event_showCategory({ day }) {
      this.selectDay = day.date;
      this.selectViewType = 'category';
    },
    async getShopName() {
      this.shops = await util.callCloudFunction('getShop');
      this.selectShop = this.shops[0];
      this.categories = [...Array(this.selectShop.capacity).keys()].map(i => String(i + 1));
    },
    async addReservation() {
      this.submit = true;
      const param = {
        shop: this.selectShop,
        user: this.param.member,
        date: this.param.date.date,
        time: this.param.date.time,
        type: this.param.type,
        note: this.param.note,
        category: parseInt(this.param.category, 10),
        instructor: this.param.instructor,
      };
      const path = param.type === 'メンテナンス' ? 'addMaintenanceReservation' : 'addReservation';
      await util.callCloudFunction(path, param)
        .then(async () => {
          this.$store.commit('snackbar/open', { text: '予約を正常に受付けました。', color: 'success' });
          await this.updateDailyEvent();
          this.close();
          this.submit = false;
        }).catch(error => {
          error = error === 'INTERNAL' ? '予約を受付けることができませんでした。' : error;
          this.$store.commit('snackbar/open', { text: error, color: 'error' });
          this.submit = false;
        });
    },
    close() {
      this.dialog = false;
      this.confirm = false;
    },
    async deleteReservation() {
      this.submit = true;
      const param = {
        shop: this.selectShop,
        user: this.param.member,
        date: `${this.param.date.date} ${this.param.date.time}`,
        type: this.param.type,
        category: parseInt(this.param.category, 10),
        reservationId: this.param.reservationId,
      };
      await util.callCloudFunction('delReservation', param)
        .then(async () => {
          this.$store.commit('snackbar/open', { text: '予約を削除しました。', color: 'success' });
          await this.updateDailyEvent();
          this.confirm = false;
          this.dialog = false;
          this.submit = false;
        }).catch(error => {
          error = error === 'INTERNAL' ? '予約を削除できませんでした。' : error;
          this.$store.commit('snackbar/open', { text: error, color: 'error' });
          this.submit = false;
        });
    },
    async updateShift() {
      this.loading = true;
      const monthShift = await util.callCloudFunction('getWorkShift', { month: this.selectMonth, shopId: this.selectShop.id });
      if (monthShift) {
        this.shiftStatus = monthShift.status;
        this.events = monthShift.schedule.map((v) => {
          v.color = this.setColor(v.type);
          v.start = v.date;
          v.timed = false;
          if (v.type === 'レッスン') {
            const names = v.instructors.map((instructor) => instructor.name);
            v.name = names.join(', ');
          } else {
            v.name = v.type;
          }
          return v;
        });
        // this.events = monthShift.schedule.map((v) => {
        //   v.color = this.setColor(v.type);
        //   return v;
        // });
      } else {
        this.shiftStatus = false;
        this.events = [];
      }
      this.loading = false;
    },
    async updateDailyEvent() {
      this.loading = true;
      this.focus = this.selectDay;
      this.dailyEvents = [];
      const dailyEvents = await util.callCloudFunction('getReservation', { date: this.selectDay, shopId: this.selectShop.id });
      const currentDay = await util.callCloudFunction('getEmptySheat', { date: this.selectDay, shopId: this.selectShop.id, isNotMember: true });
      this.isHoliday = currentDay.isHoliday;
      this.isBefore = moment(this.selectDay).isBefore(moment().add(-1, 'days'));
      if (currentDay.byTime === 'empty') {
        this.dailyEvents = dailyEvents;
        this.dailyEvents.forEach((v) => {
          v.color = this.setColor(v.type);
        });
        this.isEmpty = true;
        this.loading = false;
        return;
      }
      this.isEmpty = false;
      currentDay.byTime.forEach((v) => {
        const emptyLefty = v.leftyPosition.filter(i => v.emptyCategory.indexOf(i) !== -1);
        const exceptLefty = v.emptyCategory.filter(i => v.leftyPosition.indexOf(i) === -1);
        // 開始時刻から20分以上経過で予約不可とする。
        const limit = 20;
        const entryTime = moment(`${this.selectDay} ${v.time}`, 'YYYY-MM-DD HH:mm');
        const isPastTime = entryTime.diff(moment(), 'minutes') >= limit;
        if (v.lesson) {
          exceptLefty.forEach((category) => {
            const info = {
              name: '空き',
              category: category.toString(),
              start: `${this.selectDay} ${v.time}`,
              type: this.SHIFT_TYPE.empty.text,
              end: moment(`${this.selectDay} ${v.time}`).add(1, 'hour').format('YYYY-MM-DD HH:mm'),
              instructor: {
                name: v.instructor.name,
                nameKana: v.instructor.nameKana,
                uid: v.instructor.uid,
              },
              // instructorName: v.instructorName,
              // instructorNameKana: v.instructorNameKana,
              // instructorUid: v.instructorUid,
              isPastTime,
              lefty: false,
            };
            dailyEvents.push(info);
          });

          emptyLefty.forEach((category) => {
            const info = {
              name: exceptLefty.length ? '空き(レフティ)' : '空き(レフティ+)',
              category: category.toString(),
              start: `${this.selectDay} ${v.time}`,
              type: this.SHIFT_TYPE.empty.text,
              end: moment(`${this.selectDay} ${v.time}`).add(1, 'hour').format('YYYY-MM-DD HH:mm'),
              instructor: {
                name: v.instructor.name,
                nameKana: v.instructor.nameKana,
                uid: v.instructor.uid,
              },
              // instructorName: v.instructorName,
              // instructorNameKana: v.instructorNameKana,
              // instructorUid: v.instructorUid,
              isPastTime,
              lefty: true,
            };
            dailyEvents.push(info);
          });
        } else if (v.free) {
          emptyLefty.forEach((category) => {
            const info = {
              name: exceptLefty.length ? 'レフティ' : 'レフティ+',
              category: category.toString(),
              start: `${this.selectDay} ${v.time}`,
              type: this.SHIFT_TYPE.lefty.text,
              end: moment(`${this.selectDay} ${v.time}`).add(1, 'hour').format('YYYY-MM-DD HH:mm'),
              instructor: {
                name: v.instructor.name,
                nameKana: v.instructor.nameKana,
                uid: v.instructor.uid,
              },
              // instructorName: v.instructorName,
              // instructorNameKana: v.instructorNameKana,
              // instructorUid: v.instructorUid,
              isPastTime,
              lefty: true,
            };
            dailyEvents.push(info);
          });
        }
      });
      this.dailyEvents = dailyEvents;
      this.dailyEvents.forEach((v) => {
        v.color = this.setColor(v.type);
      });
      this.loading = false;
    },
    prevDay() {
      this.selectDay = moment(this.selectDay, 'YYYY-MM-DD').add(-1, 'day').format('YYYY-MM-DD');
    },
    prevMonth() {
      this.selectMonth = moment(this.selectMonth, 'YYYY-MM').add(-1, 'month').format('YYYY-MM');
    },
    nextDay() {
      this.selectDay = moment(this.selectDay, 'YYYY-MM-DD').add(1, 'day').format('YYYY-MM-DD');
    },
    nextMonth() {
      this.selectMonth = moment(this.selectMonth, 'YYYY-MM').add(1, 'month').format('YYYY-MM');
    },
    // adjustment() {
    //   const div = document.getElementById('calendar');
    //   const result = div.getElementsByClassName('v-event v-event-start v-event-end');
    //   for (const v of result) {
    //     v.style.cssText = 'height: 50%; width: 95%; position: relative;';
    //     v.firstChild.style.cssText = 'position: absolute; top: 50%; left: 50%; transform: translate(-50%,-50%); font-size: 15px; color: black; font-weight: bold;';
    //   }
    // },
    adjustmentCategory() {
      const div = document.getElementById('category');
      const result = div.getElementsByClassName('v-event-timed');
      for (const v of result) {
        // v.firstChild.className = 'd-flex flex-column text-lg-subtitle-2 text-caption align-center pt-5 black--text';
        const text = v.firstChild.textContent;
        if (text.indexOf(',') !== -1) {
          v.firstChild.textContent = text.substring(0, text.indexOf(','));
        }
      }
    },
    setColor(type) {
      for (const property in this.SHIFT_TYPE) {
        if (this.SHIFT_TYPE[property].text === type) {
          return this.SHIFT_TYPE[property].color;
        }
      }
      return '';
    },
  },
};
</script>
<style>
.fade-enter-active, .fade-leave-active {
  transition: opacity .4s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
.v-event.v-event-start.v-event-end {
  height: 35% !important;
  width: 90% !important;
  position: relative !important;
}
.v-event.v-event-start.v-event-end > div {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  font-size: 14px;
  color: black;
  font-weight: bold;
}
.v-event-timed > div {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  font-size: 14px;
  color: black;
}
</style>
